import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import { CompatibleImg } from '../components/Img';
import PageLayout from '../components/PageLayout';
import {
    RichTextView,
    device,
    Signature,
    ContentMain,
} from '../components/commonStyled';
import point1 from '../images/1-list.png';
import point2 from '../images/2-list.png';
import point3 from '../images/3-list.png';
import point4 from '../images/4-list.png';
import point5 from '../images/5-list.png';
import Sections from '../components/Sections';
import { FONT_GOTHAM_MEDIUM } from '../styles/fonts';
import SectionTitle from '../components/SectionTitle';
import { Point } from '../components/about/Point';
import { FeatureBlock } from '../components/about/FeatureBlock';
import SectionDevider from '../components/SectionDevider';

const POINTS_IMAGES = [point1, point2, point3, point4, point5];

export default ({ data, location }) => {
    const {
        title,
        content,
        featured_media,
        seo,
        common_acf: { blocks },
        about_acf: {
            point_1,
            point_2,
            point_3,
            point_4,
            point_5,
            signature,
            subTitle,
            endingOfPoints,
            centreDirections_1,
            centreDirections_2,
            centreDirections_3,
            centreDirectioctionsTitle,
            action_block,
        },
    } = data?.wpPage;

    const POINTS_TEXT = [point_1, point_2, point_3, point_4, point_5];
    const DIRECTIONS = [
        centreDirections_1,
        centreDirections_2,
        centreDirections_3,
    ];

    return (
        <PageLayout
            breadcrumbs={[{ url: location?.pathname, title }]}
            title={title}
            seoMeta={seo}
        >
            <Container>
                <AboutContainer>
                    <div>
                        <RichTextView
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                        <SignatureStyled>{signature}</SignatureStyled>
                    </div>
                    <ImageWrap>
                        {featured_media?.node?.localFile && (
                            <CompatibleImg
                                image={featured_media?.node?.localFile}
                            />
                        )}
                    </ImageWrap>
                </AboutContainer>
                <PointsTitle>{subTitle}</PointsTitle>
                <PointsContainer>
                    {POINTS_IMAGES.map(
                        (image, index) =>
                            POINTS_TEXT[index] && (
                                <Point
                                    key={index}
                                    image={image}
                                    description={
                                        POINTS_TEXT[index]?.description
                                    }
                                    pointText={POINTS_TEXT[index]?.pointText}
                                />
                            )
                    )}
                </PointsContainer>
                <RichTextView
                    dangerouslySetInnerHTML={{
                        __html: endingOfPoints,
                    }}
                />
            </Container>
            <SectionDevider />
            <SectionTitle title={centreDirectioctionsTitle} />
            <DirectionsBlocks>
                {DIRECTIONS.map((item) => (
                    <FeatureBlock
                        key={item.title}
                        image={item.centreDirectionsImage}
                        title={item.title}
                        description={item.description}
                    />
                ))}
            </DirectionsBlocks>
            <Sections
                enabledSections={blocks}
                options={{
                    actionBlockBottom: {
                        infoText: action_block.infoText,
                        buttonText: action_block.buttonText,
                        highlightedText: action_block.highlightedText,
                        title: action_block.title,
                    },
                }}
            />
        </PageLayout>
    );
};

const DirectionsBlocks = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const PointsTitle = styled.div`
    font-size: 24px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    line-height: 30px;
    text-align: center;
    margin-bottom: 36px;

    @media ${device.laptopSmall} {
        margin-bottom: 12px;
    }
`;

const SignatureStyled = styled(Signature)`
    text-align: right;
    margin-top: 57px;

    @media ${device.laptopSmall} {
        margin-top: 12px;
        font-size: 55px;
        text-align: left;
    }
`;

const PointsContainer = styled.div``;

const Container = styled(ContentMain)`
    display: block;
`;

const AboutContainer = styled.div`
    display: flex;
    margin-bottom: 67px;

    @media ${device.laptopSmall} {
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 36px;
    }
`;

const ImageWrap = styled.div`
    width: 525px;
    flex-shrink: 0;
    margin-left: 50px;
    overflow: hidden;
    border-radius: 15px;

    @media ${device.laptopSmall} {
        overflow: hidden;
        margin-bottom: 36px;
        margin-right: 0;
        margin-left: 0;
    }

    @media ${device.mobileMedium} {
        width: 100%;
        margin-bottom: 24px;
    }
`;

export const pageQuery = graphql`
    query AboutPage {
        wpPage(common_acf: { id: { eq: "about" } }) {
            title
            content
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
            about_acf {
                signature
                subTitle: subtitle
                point_1: point1 {
                    description
                    pointText: pointtext
                }
                point_2: point2 {
                    description
                    pointText: pointtext
                }
                point_3: point3 {
                    description
                    pointText: pointtext
                }
                point_4: point4 {
                    description
                    pointText: pointtext
                }
                point_5: point5 {
                    description
                    pointText: pointtext
                }
                endingOfPoints: endingofpoints
                centreDirectioctionsTitle: centredirectioctionstitle
                centreDirections_1: centredirections1 {
                    description
                    centreDirectionsImage: centredirectionsimage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 200)
                            }
                        }
                    }
                    title
                }
                action_block: actionBlock {
                    infoText: infotext
                    title: title
                    buttonText: buttontext
                    highlightedText: highlightedtext
                }
                centreDirections_2: centredirections2 {
                    description
                    centreDirectionsImage: centredirectionsimage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 200)
                            }
                        }
                    }
                    title
                }
                centreDirections_3: centredirections3 {
                    description
                    centreDirectionsImage: centredirectionsimage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 200)
                            }
                        }
                    }
                    title
                }
            }
            featured_media: featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 600)
                        }
                    }
                }
            }
        }
    }
`;
