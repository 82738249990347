import React from 'react';
import styled from '@emotion/styled';

import { SharpImage } from '../../models';
import { CompatibleImg } from '../../components/Img';
import {
    RichTextView,
    PointBlock,
    device,
} from '../../components/commonStyled';
import { FONT_GOTHAM_MEDIUM } from '../../styles/fonts';

interface FeatureBlockProps {
    image: SharpImage;
    title: string;
    description: string;
}

export const FeatureBlock: React.FC<FeatureBlockProps> = ({
    image,
    title,
    description,
}) => {
    return (
        <PointBlockStyled>
            <DirectionImage>
                <CompatibleImg image={image?.localFile} />
            </DirectionImage>
            <DirectionTitle>{title}</DirectionTitle>
            <PointBlockText
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
        </PointBlockStyled>
    );
};

const PointBlockText = styled(RichTextView)`
    padding: 0 26px 0 30px;

    li:before {
        content: '\\2022';
        color: #000;
    }
`;

const PointBlockStyled = styled(PointBlock)`
    padding: 47px 0 28px 0;
    margin-bottom: 18px;

    &:not(:last-child) {
        margin-right: 18px;
    }

    @media ${device.mobileMedium} {
        padding-top: 36px;
        margin-left: 12px;
        margin-right: 12px;
    }
`;

const DirectionImage = styled.div`
    display: block;
    align-items: center;
    width: 108px;
    height: 142px;
    margin: 0 auto;

    @media ${device.mobileMedium} {
        height: 128px;
    }
`;

const DirectionTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 30px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    line-height: 34px;
    text-align: center;
    margin-bottom: 31px;
    min-height: 67px;
`;
