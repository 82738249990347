import React from 'react';
import styled from '@emotion/styled';

import { RichTextView, device, deviceSize } from '../commonStyled';
import { DEFAULT_ALT } from '../../constants';

interface PointProps {
    image: string;
    description: string;
    pointText: string;
}

export const Point: React.FC<PointProps> = ({
    description,
    image,
    pointText,
}) => {
    return (
        <PointContainer>
            <PointDescription>
                <RichTextView
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            </PointDescription>
            <PointItem>
                <PointImage src={image} alt={DEFAULT_ALT} />
                <PointText>
                    <RichTextView
                        dangerouslySetInnerHTML={{
                            __html: pointText,
                        }}
                    />
                </PointText>
            </PointItem>
        </PointContainer>
    );
};

const PointContainer = styled.div`
    position: relative;
    z-index: 1;
    padding: 36px 0 21px 0;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -53px;
        right: -53px;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        border-radius: 22px;
        background: #fff;
        box-shadow: 9px 7px 65px 0 #d8cece;
        z-index: -1;

        @media ${device.laptopSmall} {
            display: none;
        }
    }

    @media (min-width: ${deviceSize.laptopL}) {
        &:hover {
            margin-left: -53px;
            margin-right: -53px;

            ${RichTextView} {
                font-size: 22px;
            }

            &:after {
                opacity: 1;
            }
        }
    }

    @media ${device.laptopSmall} {
        margin-bottom: 0px;
        padding-bottom: 0;
    }
`;

const PointDescription = styled.div`
    margin-bottom: 21px;
`;

const PointItem = styled.div`
    display: flex;
    align-items: center;

    @media ${device.mobileMedium} {
        flex-direction: column;
        align-items: center;
    }
`;

const POINT_IMAGE_PADDING = 24;
const POINT_IMAGE_WIDTH = 131;
const POINT_IMAGE_WIDTH_LAPTOP = 50;

const PointImage = styled.img`
    width: ${POINT_IMAGE_WIDTH}px;
    flex-shrink: 0;
    max-width: 100%;
    height: auto;
    margin-right: ${POINT_IMAGE_PADDING}px;

    @media ${device.laptopSmall} {
        width: ${POINT_IMAGE_WIDTH_LAPTOP}px;
    }

    @media ${device.mobileMedium} {
        margin-bottom: 15px;
    }
`;

const PointText = styled.div``;
